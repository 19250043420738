/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"
import List from "../../components/List"

import TitleImg from "../../images/industries-food-banks.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Food Banks"
      description="With Ceres, implemented and supported by SimCrest, you get world class knowledge of distribution’s best practices based on your specific Food Bank requirements."
      linkSubPageGroupID="IndustriesGroup"
      titleImg={TitleImg}
      infoList={[
        {
          title: "Specialty Software for Food Banks",
          text: `Food banks are receiving and distributing tons of food every year. You need a system to keep track of donations, grants, food drive, and mobile delivery. Most out-of-the-box accounting solutions can’t handle the needs of your food bank, so you may be using Excel or other tools to keep track of transactions that is not supported by the software. That causes issues with missing integration of all the different software packages.<br/><br/>Ceres is a food bank system specifically developed to handle the unique requirements of food banks. The need for food in your community is on the rise. New programs come along that need to be handled by your food bank, so you need a system that can support all the new initiatives and something that can grow with your food bank. Ceres is a flexible and scalable system that fits food banks. Most of the largest food banks all over the United States use Ceres to manage their operations.`,
        },
        {
          title: "SimCrest is a Ceres Expert",
          text: (
            <p>
              With Ceres, implemented and supported by SimCrest, you get world
              class knowledge of distribution’s best practices based on your
              specific Food Bank requirements.
              <br />
              <br />
              Ceres is a business solution powered by Microsoft Dynamics
              Business Central that is specifically created to help Food Banks
              manage their needs. It gives users office areas such as the
              General Ledger, Accounts Payables, Accounts Receivables, Cash
              Management, and more. Instead of working with Excel, for example,
              Ceres gives you more specifics and is simple to use. All your
              information will be in one system that links with your Financial
              Donation / CRM solution. It also has the ability to be accessed by
              multiple people at once but also helps avoid double entry of data.
              <h5 style={{ marginTop: "2rem" }}>
                Ceres and Dynamics Business Central allow you to:
              </h5>
              <List
                className="concise dark"
                items={[
                  {
                    text: `Perform all tasks in one single solution`,
                  },
                  {
                    text: `Access information from anywhere in the world`,
                  },
                  {
                    text: `Fit your unique way of doing business`,
                  },
                  {
                    text: `Enjoy flexibility and simplicity`,
                  },
                  {
                    text: `Improved sales and customer satisfaction`,
                  },
                  {
                    text: `Increased information visibility for decision making`,
                  },
                  {
                    text: `Increased capability in managing financial information`,
                  },
                ]}
              />
            </p>
          ),
        },
      ]}
    />
  )
}
